import React, { useState, useEffect } from "react";
import axios from "axios";
import Order from "./Order";
import SkeletonLoading from "./SkeletonLoading";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import Notification from "./Notification";
import logo from "../assests/logo.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
const Dashboard = () => {
  const [checks, setChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const fetchAllChecks = async () => {
    try {
      const response = await axios.get(
        "https://api.foodyno.gomaplus.tech/api/orders/Casher/1",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setChecks(response.data?.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeCheckStatus = async (checkId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://api.foodyno.gomaplus.tech/api/order/ChangeToPaid/${checkId}`,
        {},

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchAllChecks();
    } catch (error) {
      console.error("Error changing check status:", error);
    }
  };

  useEffect(() => {
    fetchAllChecks();
  }, []);

  useEffect(() => {
    const pusher = new Pusher("1604fd4ad916b0f5ed4d", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe("Casher.2");

    channel.bind("ToCasher", (data) => {
      console.log(data.Casher, "from real timeeee");
      let order = data.Casher;
      order.isNew = true;
      order.table = { table_num: order.table_id };
      console.log(data);
      setNotification("New check added!");
      setChecks((prev) => {
        return [order, ...prev];
      });
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  return (
    <div className="min-h-screen bg-slate-100">
      <div className="flex items-center justify-between mx-8 py-10 ">
        <img src={logo} alt="" className=" w-20 sm:w-24 md:w-32 lg:w-38 " />
        <div className="relative inline-flex  group ">
          <a
            onClick={logOut}
            className="relative inline-flex items-center justify-center    font-semibold text-[#a758ed] transition-all duration-200 font-pj rounded-xl "
            role="button"
          >
            <LogoutOutlinedIcon sx={{ fontSize: "2rem" }} />
          </a>
        </div>
      </div>

      <div className=" py-6 mx-auto">
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-3 mg:grid-cols-3 gap-3">
            {checks.map((check) => (
              <Order
                key={check.id}
                check={check}
                onOkClick={() => changeCheckStatus(check?.id)}
              />
            ))}
          </div>
        )}
      </div>

      {notification && (
        <Notification
          message={notification}
          onClose={() => setNotification("")}
        />
      )}
    </div>
  );
};

export default Dashboard;
