import React from "react";

function Order({ check, onOkClick }) {
  const handleChangeStatus = () => {
    onOkClick();
  };
  let tax =
    (check.order[0].table.branch.taxRate.replace("%", "") / 100) * check.price;
  console.log(check);
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=600");

    printWindow.document.open();
    printWindow.document.write(`
     <html>
        <head>
          <title>Print Order</title>
          <style>
            
            body {
              font-family: Arial, sans-serif;
              margin : 18px;
            }
            
            table {
            margin-top : 20px;
              width: 100%;
              border-collapse: collapse;
            }
            .top_table{
            display : flex ;
             justify-content: flex-end;

            }
            th, td {
             border: 1px solid #61B2E4;
              padding: 4px;
              text-align: right;
            }
th {
  background-color: #D6EEEE;
}
          </style>
        </head>
        <body>
          <h2>Order Details</h2>
          <div class="top_table">
          <table style="width:50%">
  <tr>
  <td>${check.id}</td>
    <th> الفاتورة</th>
  </tr>
  <tr>
  <td>${check.order[0].time_start}</td>
    <th>التاريخ</th>
  </tr>
 
</table>
    </div>
   <div>
<table>
  <tr>
  <th>السعر</th>
   <th style="width:20%">الكمية</th>
   <th style="width:20%">سعر الوحدة</th>  
  <th >الصنف</th>
  </tr>
   <tbody>
            ${check.order
              .flatMap((order) =>
                order.products.map(
                  (product) => `
                <tr>
                 <td>${product.subTotal}</td>
                
                    <td>${product.qty}</td>
                     <td>${product.price}</td>
                  <td>${product.name}</td>
                 
               
                 
                </tr>
              `
                )
              )
              .join("")}
          </tbody>
 
</table>
          </div>
   <div class="top_table">
 <table style="width:50%">
  <tr>
  <td>${check.price}</td>
    <th>المجموع الجزئي</th>
  </tr>
  <tr>
  <td>${check.order[0].table.branch.taxRate}</td>
    <th>الضريبة</th>
  </tr>
  <tr>
   <td>${check.price + tax}</td>
    <th>الرصيد المستحق</th>  
  </tr>
</table>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  return (
    <div className="flex flex-col px-2 ">
      <div
        className={`${
          check.isNew ? " border-2 border-[#d22afc]" : ""
        } overflow-x-auto max-w-xl  mx-auto bg-white rounded-lg shadow-lg min-h-[400px] `}
      >
        <div className="inline-block min-w-full py-6 px-2">
          <div className="overflow-hidden">
            {check.table_num === 1101 ? (
              <div className="bg-[#7073de]  w-32 text-white rounded-sm  py-2 px-1">
                Take Away
              </div>
            ) : null}
            <table className="min-w-full text-left text-sm   ">
              <thead className="border-b border-neutral-200 font-bold py-10 text-zinc-600  ">
                <tr>
                  <th scope="col" className="px-4 py-4">
                    Table Number
                  </th>

                  <th scope="col" className="px-4 py-4 ">
                    Orders
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="font-medium text-zinc-600 ">
                  <td valign="top" className=" px-4 py-4 font-bold text-black ">
                    {check.order[0].table.table_num}
                  </td>

                  <td valign="top" className="whitespace-nowrap  px-4 py-4 ">
                    <div className=" overflow-y-auto overflow-x-hidden">
                      {check.order.map((ord) => (
                        <div className="pb-2" key={ord.id}>
                          {ord.products.map((product) => (
                            <div className="pb-1 " key={product.id}>
                              <h1 className="text-sm">{product.name}</h1>
                              {product.extra.length > 0 ? (
                                <div className="flex space-x-2">
                                  <p className="text-sm">extra :</p>
                                  {product.extra.map((ext, index) => (
                                    <p key={index} className="text-sm">
                                      {ext.name}
                                    </p>
                                  ))}
                                </div>
                              ) : null}
                              {product.removeIngredient.length > 0 ? (
                                <div className="flex space-x-2">
                                  <p className="text-sm">removed :</p>
                                  {product.removeIngredient.map(
                                    (remove, index) => (
                                      <p className="text-sm" key={index}>
                                        {remove.name}
                                      </p>
                                    )
                                  )}
                                </div>
                              ) : null}

                              <h1 className="text-[#5458ca] text-sm font-semibold">
                                SubTotal :{product.subTotal}
                              </h1>

                              <div className="w-full h-0.5 bg-gray-200 my-2 "></div>
                            </div>
                          ))}
                          <p className="font-bold text-black text-sm">
                            Total Price for order : {ord.total_price}
                          </p>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td
                    valign="top"
                    className="whitespace-nowrap px-4 py-4 text-sm text-black font-bold"
                  >
                    {check.price} USD
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="py-10 space-x-2">
        <button
          onClick={handleChangeStatus}
          className="py-3 px-4 w-2/5 bg-grad bg-gradient-to-br from-[#d22afc] via-[#a758ed] to-[#7073de]  duration-200 rounded-md shadow-md shadow-zinc-600 font-medium  text-white"
        >
          OK
        </button>
        <button
          onClick={handlePrint}
          className="py-3 px-4 inline-flex  bg-[#d22afc] rounded-md shadow-md shadow-zinc-600 font-medium items-center justify-center text-white"
        >
          Print
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-printer ml-2 "
            viewBox="0 0 16 16"
          >
            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Order;
